
.category-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 88px;
  z-index: 1039;
  background-color: var(--color-ash);
  border-right: 1px solid var(--color-light-gray);
}

.nav-category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px !important;
  width: 56px !important;
  border: 0;
  border-radius: 50%;
  background-color: var(--color-ash) !important;

  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 2px 2px rgba(0, 0, 0, 0.05);
  }
}

.scrollable-wrapper {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100%;
}

.img-wrapper {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
}
