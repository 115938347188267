
.navbar {
  margin-top: var(--top-bar-height);

  &.desktop {
    min-width: 206px;
    max-width: 206px;
  }
}

.navbar-nav {
  width: 100%;
  color: var(--color-tint);
}

.font-size-title-small {
  font-size: 18px;
}

.category-title-wrapper {
  min-height: 60px;
}

.list-group {
  width: 100%;
  overflow-y: auto;
}

.width-limit {
  max-width: 290px;
}

.list-group-item {
  height: 48px;
  border-radius: 56px;
  border: none;
  padding: 1rem;
  color: var(--color-tint);

  &::before {
    display: none;
  }

  &.active {
    font-weight: bold;
    color: inherit;
    background-color: var(--color-ash);
  }

  &:focus-visible,
  &:hover {
    outline: none;
    background-color: var(--color-ash);
  }
}

::v-deep .dropdown-divider {
  margin: 0 !important;
}

.gap-2 {
  gap: 8px;
}

.lock-container {
  width: 24px;
  height: 24px;
  background-color: var(--color-accents-blush) !important;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.app-icon {
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
