
.nav-category {
  width: 100% !important;
  min-width: 63px !important;
  min-height: 64px !important;
  border: 0;
  font-size: 14px;
  background-color: initial !important;
  color: var(--color-tint) !important;
  border-radius: 0;

  &.desktop {
    min-height: 96px !important;
  }

  &.active {
    background-color: var(--color-white) !important;
  }

  &:not(.active) {
    font-weight: normal !important;
  }

  &::before {
    display: none;
  }

  &:focus-visible,
  &:hover {
    .category-icon-wrapper {
      box-shadow:
        0px 0.5px 5px rgba(0, 0, 0, 0.08),
        0px 2px 2px rgba(0, 0, 0, 0.05);
    }
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.category-icon-wrapper {
  width: 48px;
  height: 48px;

  &.desktop {
    width: 56px;
    height: 56px;
  }

  border-radius: 50% !important;
  background-color: var(--background-color);
}

.nav-category-toggle {
  height: 48px !important;
}
