
::v-deep .dropdown-item {
  border-radius: 0;

  &:hover {
    background-color: var(--color-light-gray);
  }
}

ion-icon {
  height: 20px;
  width: 20px;
  color: var(--color-deep-blue-grey);
}
