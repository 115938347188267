
.livestream {
  &__preview-image {
    height: 267px;
    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
  }
}
