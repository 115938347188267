
::v-deep .b-sidebar {
  margin-top: 64px !important;

  &-body {
    display: flex;
  }
}

.navigation-categories-mobile {
  border-right: 1px solid var(--color-light-gray);
  background-color: var(--color-ash);
}

.scrollable-wrapper {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}

::v-deep .navbar-nav {
  max-height: calc(100vh - 64px);
}
