
.registration-form {
  &__top-bar {
    height: 60px;
    width: 100%;

    box-shadow:
      0px 2px 2px rgba(0, 0, 0, 0.1),
      0px 0.5px 5px rgba(0, 0, 0, 0.15);

    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    background: #f5f5f5;
  }

  &__viewport-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-top: 3rem;
    margin-bottom: 32px;

    @media (min-height: 800px) {
      height: calc(100vh - 5rem - 5rem - 60px);
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  &__container {
    max-width: 384px;
  }

  &__steps-img {
    width: 100%;

    padding: 0 48px;

    @media (min-width: 768px) {
      padding: 0 64px;
    }
  }

  &__main-text {
    font-weight: bold;
    font-size: 24px;
    line-height: 115%;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 130%;
    }
  }

  &__checkbox-label {
    font-size: 0.75rem;
    line-height: 130%;
  }

  &__submit-button {
    height: 42px;

    font-weight: bold;
    font-size: 16px;
  }

  &__error-text {
    color: red !important;
  }
}

.btn {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));

  &:disabled {
    filter: none;
  }
}
