
.b-nav-dropdown {
  display: flex;
  min-width: 48px;
  width: 48px !important;
  height: 48px !important;
  position: relative !important;
  align-items: center;
  justify-content: center;
  border: none;

  &:focus-visible {
    outline: none;
  }

  ::v-deep .dropdown-menu {
    position: relative;
  }
}

::v-deep .dropdown-menu.show {
  position: absolute;
  right: 0 !important;
  font-size: var(--font-size-big);
  width: 224px;
  left: unset !important;
  text-align: right;
  box-shadow: none;
  border: 1px solid var(--color-light-gray);
  background-color: var(--color-egg-shell);
  border-radius: 5px 0 5px 5px;
  margin-top: 0;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.mobile {
  ::v-deep .dropdown-menu {
    top: 4px !important;
    right: 32px !important;
  }
}

::v-deep .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0 16px;
}

.dropdown-toggle {
  padding: 0 !important;
  border: none;
  width: 48px !important;
}

::v-deep .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px !important;
  height: 48px;
  border-radius: 50%;

  &:hover,
  &:focus-visible {
    outline: none;
    box-shadow:
      0px 0.5px 5px rgba(0, 0, 0, 0.08),
      0px 2px 2px rgba(0, 0, 0, 0.05);
  }
}

::v-deep .dropdown-divider {
  margin: 0;
  color: var(--color-light-gray);
}
