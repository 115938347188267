
.modal {
  color: #0f545c !important;

  &-title {
    color: #0f545c !important;
  }
  &-header {
    background-color: #deedeb !important;
    border-bottom: none;
  }

  &-body,
  &-footer {
    border-top: 1px solid rgba(15, 84, 92, 0.2);
  }
}
