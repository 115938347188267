
.font-size-title {
  font-size: 1.25rem;
}

.navbar {
  height: 64px;
  left: 0 !important;
  margin-left: -1px;
  border-bottom: var(--color-light-gray) solid 1px !important;
  background-color: var(--color-white);
  z-index: 1039;

  .app-logo {
    border-radius: 6px;
  }

  &.desktop-with-navigation {
    left: 88px !important;
  }
}

.navbar-nav {
  justify-content: space-between;
  align-items: center;
}

.nav-category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 65px !important;
  height: 64px !important;
  border: solid var(--color-light-gray);
  border-width: 0 1px 1px 0;
  border-radius: 0;
  background-color: var(--color-ash) !important;

  &:active {
    box-shadow: none !important;
  }
}

.desktop {
  .nav-category-toggle {
    &:hover {
      .img-wrapper {
        box-shadow:
          0px 0.5px 5px rgba(0, 0, 0, 0.08),
          0px 2px 2px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.img-wrapper {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
}

.upgrade-button {
  display: inline-grid;
  background-color: var(--color-rose) !important;
  margin-right: 4px;
  height: 40px;
  min-width: 40px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  padding: 0 16px;
  opacity: 0.85;
  letter-spacing: normal;
  .app-icon {
    margin-left: -12px !important;
    height: 32px;
    width: 32px;
    min-width: 32px;
    vertical-align: middle;
  }
}
