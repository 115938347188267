
.branded-iphone {
  &__image {
    height: 238px;
    width: 245px;

    background-size: contain;
    background-repeat: no-repeat;

    position: relative;
  }

  &__icon {
    width: 40px;

    position: absolute;
    bottom: 19px;
    right: 28px;

    font-size: 7px;
    line-height: 9px;
    font-weight: bold;
    color: white;
    text-align: center;

    display: flex;
    flex-direction: column;

    img {
      border-radius: 5px;
    }

    span {
      margin-top: 1px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
