
.apple-account-editor {
  &__card {
    padding: 4rem 6rem;
  }

  &__heading-icon {
    font-size: 1.25rem;
  }

  &__section-divider {
    height: 1px;
    width: 100%;
  }

  &__done-row {
    margin-top: 6rem;
  }
}
